/**
 * Компонент `ButtonAppointment` для отображения кнопки записи на прием.
 *
 * Этот компонент использует контекст `PopupContext` для управления состоянием всплывающего окна.
 * При нажатии на кнопку вызывается функция `onClickButton`, которая устанавливает состояние всплывающего окна
 * с типом 'appointment' и открывает его.
 *
 * @component
 * @param {string} type - Тип кнопки, используется для стилизации.
 */

import { useContext } from 'react';
import { PopupContext } from '../../context/context';

export default function ButtonAppointment({ type }) {
  const { setPopup } = useContext(PopupContext);

  const onClickButton = () => setPopup(prev => ({
    ...prev,
    type: 'appointment',
    isOpen: true,
  }));

  return (
    <button type="button" className={`${type} btn btn--gold popup-trigger`} onClick={onClickButton}>
      Записаться
    </button>
  );
}