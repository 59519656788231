import { Link } from 'react-router-dom';

export default function Vacancy({ vacancy }) {
  return (
    <div className="container">
      <h4 className="vacancy_title">{vacancy.title}</h4>
      <p className="vacancy__desc">{vacancy.description}</p>
      {vacancy.url.startsWith('http') ? (
        <a href={vacancy.url} className="vacancy__link link-text" target="_blank" rel="noopener noreferrer">
          Подробнее
        </a>
      ) : (
        <Link to={vacancy.url} className="vacancy__link link-text">
          Подробнее
        </Link>
      )}
    </div>
  );
} 