/**
 * Компонент `SlideHero` для отображения слайда на верхнем уровне главной страницы.
 *
 * @component
 * @param {Object} slide - Объект, содержащий информацию о слайде.
 * @param {string} slide.title - Заголовок слайда.
 * @param {string} slide.desc - Описание слайда.
 */

export default function SlideHero({ slide }) {
  return (
    <>
      <h1 className="section-hero__title">{slide.title}</h1>
      <p className="section-hero__desc">{slide.desc}</p>
    </>
  );
}