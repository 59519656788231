/**
 * Компонент кнопки "гамбургер" для управления открытием и закрытием меню.
 *
 * При клике на кнопку меняется состояние открытия меню.
 *
 * @component
 *
 * @remarks
 * В этом компоненте используются два контекста:
 * - `MenuBurgerContext` для управления состоянием открытия меню.
 * - `MenuBurgerLoopContext` для установления кнопки закрытия меню, что позволяет управлять фокусом.
 *
 * @see {@link MenuBurgerContext}
 * @see {@link MenuBurgerLoopContext}
 */

import { useContext, useEffect, useRef } from 'react';
import { MenuBurgerContext, MenuBurgerLoopContext } from '../../context/context';

export default function ButtonBurger() {
  const { isBurgerOpen, setIsBurgerOpen } = useContext(MenuBurgerContext);
  const { setBtnClose } = useContext(MenuBurgerLoopContext);
  const btnRef = useRef(null);

  const handleBurgerClick = () => {
    setIsBurgerOpen(prevState => !prevState);
    document.body.classList.toggle('menu-open', !isBurgerOpen);
  };

  useEffect(() => {
    if (btnRef.current) {
      setBtnClose(btnRef.current);
    }
  }, [setBtnClose]);

  useEffect(() => {
    if (btnRef.current) {
      const label = isBurgerOpen ? 'Закрыть навигационное меню' : 'Открыть навигационное меню';
      btnRef.current.setAttribute('aria-label', label);
      btnRef.current.setAttribute('aria-expanded', isBurgerOpen.toString());
      btnRef.current.setAttribute('aria-controls', 'navigation-menu');
    }
  }, [isBurgerOpen]);

  return (
    <button 
      className={`btn-burger ${isBurgerOpen ? 'open' : ''}`}
      onClick={handleBurgerClick}
      ref={btnRef}
      type="button"
    >
      <span aria-hidden="true"></span>
    </button>
  );
}