/**
 * Компонент `DoctorCard` отображает информацию о враче.
 *
 * Этот компонент используется для отображения краткой информации о враче, включая его фотографию, имя, должность и ссылку на подробную информацию.
 * При клике на ссылку происходит прокрутка страницы вверх и переход на страницу с полной информацией о враче.
 *
 * @component
 * @param {Object} doctor - Объект с информацией о враче.
 * @param {string} doctor.img - URL изображения врача.
 * @param {string} doctor.surname - Фамилия врача.
 * @param {string} doctor.name - Имя врача.
 * @param {string} doctor.url - URL страницы с полной информацией о враче.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import noFoto from '../../assets/img/avatar.svg';

export default function DoctorCard({ doctor }) {
  const {
    url,
    img,
    surname,
    name,
    specialty,
    specialtyFull,
    education,
    diplomas
  } = doctor;

  return (
    <Link to={`/doctors/${url}`} className="doctor-card">
      <div className="doctor-card__photo">
        <img 
          src={img || noFoto} 
          alt={`${surname} ${name}`} 
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = noFoto;
          }}
        />
      </div>
      <div className="doctor-card__info">
        <h3 className="doctor-card__name">{surname} {name}</h3>
        <p className="doctor-card__specialty">{specialty}</p>
      </div>
    </Link>
  );
}
