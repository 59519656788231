/**
 * Компонент для отображения дополнительных данных в разделе Контакты.
 *
 * Этот компонент перебирает массив `contactsRules`, полученный из '../../data/contacts',
 * и каждый элемент массива отрисовывает с помощью компонента `СontactsRule`.
 *
 * @component
 */

import React, { useState, useEffect, useContext } from 'react';
import { DoctorsContext } from '../../App';
import ContactsRule from '../elements/ContactsRule';

export default function ContactsRules() {
  const { baseUrl } = useContext(DoctorsContext);
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/contacts`);
        const data = await response.json();
        setContacts(data);
      } catch (error) {
        console.error('Ошибка загрузки контактов:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  if (!contacts) {
    return null;
  }

  const rules = [
    {
      title: 'Руководитель',
      director: contacts.director_name,
      desc: `Часы приёма: ${contacts.director_hours}`,
      link: true
    }
  ];

  return (
    <section className="contact-page">
      {rules.map((rule, index) => (
        <ContactsRule key={index} rule={rule} />
      ))}
    </section>
  );
}