import { API_CONFIG } from './config';

export const doctorsApi = {
  // Получить всех докторов
  fetchDoctors: () => 
    API_CONFIG.fetchApi('/api/doctors'),

  // Получить одного доктора по URL
  fetchDoctorByUrl: (url) => 
    API_CONFIG.fetchApi(`/api/doctors/${url}`),

  // Создать или обновить доктора
  saveDoctor: (data) =>
    API_CONFIG.fetchApi('/api/doctors', {
      method: 'POST',
      body: JSON.stringify(data)
    }),

  // Удалить доктора
  deleteDoctor: (id) =>
    API_CONFIG.fetchApi(`/api/doctors/${id}`, {
      method: 'DELETE'
    })
}; 