import React from 'react';
import '../../scss/components/Modal.scss';

export default function Modal({ children, onClose, onSave }) {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>&times;</button>
        <form className="edit-document-form" onSubmit={(e) => {
          e.preventDefault();
          onSave(e);
        }}>
          {children}
        </form>
      </div>
    </div>
  );
} 