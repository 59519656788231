/**
 * Компонент страницы "Контакты" для отображения списка контактов и правил.
 *
 * @component
 */

import Hero from '../layout/Hero';
import ContactsList from '../blocks/ContactsList';
import ContactsRules from '../blocks/ContactsRules';

export default function ContactsPage() {
  document.title = 'Dr. Teeth | Контакты';
  
  return (
    <>
      <Hero height="auto">
          <ContactsList />
      </Hero>
      <ContactsRules />
    </>
  );
}