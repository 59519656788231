import { createContext } from "react";

export const PopupContext = createContext();
export const MenuBurgerContext = createContext({
  isBurgerOpen: false,
  setIsBurgerOpen: () => {},
});
export const MenuBurgerLoopContext = createContext({
  btnClose: null,
  setBtnClose: () => {},
});