import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import { vacanciesApi } from '../../../api/vacancies';
import 'react-responsive-modal/styles.css';

export default function VacanciesManager() {
  const [vacancies, setVacancies] = useState([]);
  const [editingVacancy, setEditingVacancy] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    fetchVacancies();
  }, []);

  const fetchVacancies = async () => {
    try {
      const data = await vacanciesApi.fetchVacancies();
      
      if (!Array.isArray(data)) {
        setVacancies([]);
        setError('Получены некорректные данные');
        return;
      }
      
      setVacancies(data);
      setError(null);
    } catch (error) {
      if (error.status === 401) {
        setAuthError(true);
        return;
      }
      console.error('Ошибка при загрузке вакансий:', error);
      setError(error.message);
      setVacancies([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const vacancy = {
      id: editingVacancy?.id || uuidv4(),
      title: formData.get('title'),
      description: formData.get('description'),
      url: formData.get('url'),
      isActive: formData.get('isActive') === 'true'
    };

    try {
      if (editingVacancy) {
        await vacanciesApi.updateVacancy(editingVacancy.id, vacancy);
      } else {
        await vacanciesApi.createVacancy(vacancy);
      }
      await fetchVacancies();
      handleCloseModal();
    } catch (error) {
      console.error('Ошибка при сохранении вакансии:', error);
      setError(error.message);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Удалить вакансию?')) return;

    try {
      await vacanciesApi.deleteVacancy(id);
      await fetchVacancies();
    } catch (error) {
      console.error('Ошибка при удалении вакансии:', error);
      setError(error.message);
    }
  };

  const handleEdit = (vacancy) => {
    setEditingVacancy(vacancy);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setEditingVacancy(null);
    setIsModalOpen(false);
  };

  if (authError) {
    return (
      <div>
        <p>Ошибка авторизации. Пожалуйста, <Link to="/admin">войдите снова</Link>.</p>
      </div>
    );
  }

  if (loading) return <div>Загрузка...</div>;
  if (error) return <div>Ошибка: {error}</div>;

  return (
    <div className="vacancies-manager">
      <h2>Управление вакансиями</h2>
      
      <button 
        className="add-button"
        onClick={() => handleEdit({
          id: uuidv4(),
          title: '',
          description: '',
          url: '',
          isActive: true
        })}
      >
        Добавить вакансию
      </button>

      <div className="vacancies-list">
        <h3>Список вакансий</h3>
        {Array.isArray(vacancies) && vacancies.length > 0 ? (
          vacancies.map(vacancy => (
            <div key={vacancy.id} className="vacancy-item">
              <h4>{vacancy.title}</h4>
              <p>{vacancy.description}</p>
              <div className="vacancy-actions">
                <button onClick={() => handleEdit(vacancy)}>
                  Редактировать
                </button>
                <button onClick={() => handleDelete(vacancy.id)}>
                  Удалить
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Нет доступных вакансий</p>
        )}
      </div>

      <Modal 
        open={isModalOpen} 
        onClose={handleCloseModal}
        center
      >
        {editingVacancy !== null && (
          <VacancyEditForm
            vacancy={editingVacancy}
            onSave={handleSubmit}
            onCancel={handleCloseModal}
          />
        )}
      </Modal>
    </div>
  );
}

function VacancyEditForm({ vacancy, onSave, onCancel }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(e);
  };

  return (
    <form onSubmit={handleSubmit} className="vacancy-edit-form">
      <h2>{vacancy?.id ? 'Редактирование вакансии' : 'Добавление вакансии'}</h2>
      
      <div>
        <label>
          Название:
          <input 
            name="title" 
            required 
            defaultValue={vacancy?.title}
          />
        </label>
      </div>
      
      <div>
        <label>
          Описание:
          <textarea 
            name="description" 
            required 
            defaultValue={vacancy?.description}
          />
        </label>
      </div>
      
      <div>
        <label>
          URL:
          <input 
            name="url"
            required
            defaultValue={vacancy?.url}
          />
        </label>
      </div>
      
      <div>
        <label>
          Активна:
          <select 
            name="isActive" 
            defaultValue={vacancy?.isActive ?? true}
          >
            <option value="true">Да</option>
            <option value="false">Нет</option>
          </select>
        </label>
      </div>
      
      <div className="form-actions">
        <button type="submit">
          {vacancy.id ? 'Сохранить' : 'Добавить'}
        </button>
        <button type="button" onClick={onCancel}>
          Отмена
        </button>
      </div>
    </form>
  );
} 