/**
 * Компонент `Registration` для отображения списка регистрационных документов.
 *
 * Этот компонент получает данные о регистрационных документах через API
 * и отображает их с помощью компонента `RegistrationItem`.
 *
 * @component
 */

import { useState, useEffect } from 'react';
import { documentsApi } from '../../api/documents';
import RegistrationItem from '../elements/RegistrationItem';

export default function Registration() {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const data = await documentsApi.fetchRegistrations();
        setRegistrations(data);
      } catch (error) {
        console.error('Ошибка при загрузке регистраций:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRegistrations();
  }, []);

  if (loading) return <div className="registration__loading">Загрузка...</div>;
  if (error) return <div className="registration__error">Ошибка: {error}</div>;
  if (registrations.length === 0) return null;

  return (
    <div className="company-details">
      <div className="container">
        <h2>Регистрационные документы</h2>
        <div className="company-info__row">
          {registrations.map(reg => (
            <div key={reg.id} className="company-info__col">
              <RegistrationItem reg={reg} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}