/**
 * Компонент `Feedback` отображает слайдер отзывов.
 *
 * Этот компонент использует библиотеку Swiper для создания слайдера отзывов.
 * Отзывы извлекаются из массива `reviews`, импортированного из '../../data/reviews',
 * и с помощью компонентов `Swiper`, `SwiperSlide` и `SlideFeedback` отображаются в слайдере 
 * с настройками аутентификации, пагинации и адаптивностью.
 *
 * @component
 */

import { Swiper } from '../elements/Swiper';
import { SwiperSlide } from '../elements/SwiperSlide';
import { reviews } from '../../data/reviews';
import SlideFeedback from '../elements/SlideFeedback';

export default function Feedback() {
  return (
    <section className="section section--feedback">
      <div className="container">
        <div className="section__head">
          <h2 className="heading-secondary">О нас говорят</h2>
          <div className="swiper-pagination" id="feedback-slider-pagination"></div>
        </div>

        <div className="section__slider">
          <Swiper
            id="feedback-slider"
            loop="true"
            spaceBetween={40}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              1366: {
                slidesPerView: 2
              },
            }}
            pagination={{
              el: '#feedback-slider-pagination',
              type: 'bullets',
              clickable: true
            }}
          >
            {reviews.map(r => (
              <SwiperSlide key={r.id}>
                <SlideFeedback review={r} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}