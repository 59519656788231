import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

export default function AdminSidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin');
  };

  return (
    <aside className="admin-sidebar">
      <h2 className="admin-sidebar__title">Панель управления</h2>
      
      <nav className="admin-sidebar__nav">
        <ul className="admin-sidebar__list">
          <li className="admin-sidebar__item">
            <Link 
              to="/admin/doctors" 
              className={`admin-sidebar__link ${location.pathname === '/admin/doctors' ? 'active' : ''}`}
            >
              Врачи
            </Link>
          </li>
          <li className="admin-sidebar__item">
            <Link 
              to="/admin/documents" 
              className={`admin-sidebar__link ${location.pathname === '/admin/documents' ? 'active' : ''}`}
            >
              Документы
            </Link>
          </li>
          <li className="admin-sidebar__item">
            <Link 
              to="/admin/contacts" 
              className={`admin-sidebar__link ${location.pathname === '/admin/contacts' ? 'active' : ''}`}
            >
              Контакты
            </Link>
          </li>
          <li className="admin-sidebar__item">
            <Link 
              to="/admin/vacancies" 
              className={`admin-sidebar__link ${location.pathname === '/admin/vacancies' ? 'active' : ''}`}
            >
              Вакансии
            </Link>
          </li>
          <li className="admin-sidebar__item">
            <Link 
              to="/admin/prices" 
              className={`admin-sidebar__link ${location.pathname === '/admin/prices' ? 'active' : ''}`}
            >
              Прайс-лист
            </Link>
          </li>
        </ul>
      </nav>

      <button 
        className="admin-sidebar__logout"
        onClick={handleLogout}
      >
        Выйти
      </button>
    </aside>
  );
} 