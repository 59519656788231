/**
 * Компонент `Price` отображает список услуг в виде аккордеона.
 *
 * @component
 * @param {Object[]} services - Массив объектов услуг, полученных из '../../data/services'.
 * @param {string} services[].id - Уникальный идентификатор услуги.
 *
 * Каждый элемент списка представлен компонентом `AccordionItem`.
 * Компонент также предоставляет ссылку для скачивания полного прайса в формате PDF.
 */

import { useState, useEffect, useContext } from 'react';
import { DoctorsContext } from '../../App';
import AccordionItem from '../elements/AccordionItem';
import { pricesApi } from '../../api/prices';

export default function Price() {
  const { baseUrl } = useContext(DoctorsContext);
  const [categories, setCategories] = useState([]);
  const [priceFileUrl, setPriceFileUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [baseUrl]);

  const fetchData = async () => {
    try {
      // Используем API-клиент вместо прямых fetch-запросов
      const data = await pricesApi.getAllCategories();
      
      // Преобразуем services из JSON строки в объект для каждой категории
      const processedData = data.map(category => ({
        ...category,
        services: typeof category.services === 'string' 
          ? JSON.parse(category.services)
          : category.services
      }));
      
      setCategories(processedData);

      // Проверяем наличие файла прайса через API-клиент
      const fileInfo = await pricesApi.getPriceFileInfo();
      if (fileInfo) {
        setPriceFileUrl(`${baseUrl}/api/prices/files/current`);
      }
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Загрузка...</div>;
  if (error) return <div>Ошибка: {error}</div>;

  return (
    <section className="section section--price">
      <div className="container">
        <div className="section__head">
          <h2 className="heading-secondary" id="prices">Цены на популярные услуги</h2>
          {priceFileUrl && (
            <a href={priceFileUrl} className="link-text" download>
              Скачать полный прайс в PDF
            </a>
          )}
        </div>

        <div className="accordion">
          <ul className="accordion__list" aria-labelledby="prices">
            {categories.map(category => (
              <li className="accordion__item" key={category.id}>
                <AccordionItem 
                  data={{
                    name: category.name,
                    services: category.services // Теперь это уже объект, не нужно парсить
                  }} 
                />              
              </li>
            ))}            
          </ul>
        </div>
      </div>
    </section>
  );
}