/**
 * Компонент `Contact` для отображения информации о контакте с использованием иконки и текста.
 *
 * Компонент использует компонент `Link` из `react-router-dom` для навигации по ссылке,
 * указанной в `contact.url`. Также используется SVG иконка, идентификатор которой
 * определяется через `contact.svgId`.
 * Текст контакта отображается в элементе с классом, который зависит от типа контакта (`contact.type`).
 *
 * @component
 * @param {Object} contact - Объект с информацией о контакте.
 * @param {string} contact.url - URL для навигации при клике на контакт.
 * @param {string} contact.svgId - Идентификатор иконки в SVG-файле.
 * @param {string} contact.text - Текст контакта.
 * @param {string} contact.type - Тип контакта, который определяет CSS-класс для текста.
 */

import { Link } from 'react-router-dom';
import icons from "../../assets/img/icons.svg";

export default function Contact({ contact }) {
  return (
    <Link to={contact.url} className="contacts-list__link">
      <div className="contacts-list__icon-box">
        <svg className="contacts-list__icon">
          <use xlinkHref={`${icons}#icon-${contact.svgId}`}></use>
        </svg>
      </div>
      <div className={`contacts-list__text contacts-list__text--${contact.type}`}>{contact.text}</div>
    </Link>
  );
}