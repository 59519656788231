function CategoryForm({ category, onSave, onCancel }) {
  return (
    <form onSubmit={onSave} className="category-edit-form">
      <h2>{category.order_index !== undefined ? 'Редактирование категории' : 'Добавление категории'}</h2>
      
      <div>
        <label>
          Название:
          <input 
            name="name" 
            required 
            defaultValue={category.name}
          />
        </label>
      </div>
      
      <div className="form-actions">
        <button type="submit">
          {category.order_index !== undefined ? 'Сохранить' : 'Добавить'}
        </button>
        <button type="button" onClick={onCancel}>
          Отмена
        </button>
      </div>
    </form>
  );
}

export default CategoryForm; 