/**
 * Компонент для отображения информации о регистрационных документах.
 *
 * Этот компонент используется для отображения деталей документов, включая название, номер, дату выдачи и форму документа.
 *
 * @component
 * @param {Object} reg - Объект с информацией о регистрационном документе.
 * @param {string} reg.name - Название документа.
 * @param {string} reg.number - Номер документа.
 * @param {string} reg.issued - Место выдачи документа.
 * @param {string} reg.dateOfIssue - Дата выдачи документа.
 * @param {string} reg.form - Форма документа.
 */

import icons from "../../assets/img/icons.svg";

export default function RegistrationItem({ reg}) {
  return (
    <div className="company-details__item">
      <h3 className="company-details__item-title">
        <div>
          <svg>
            <use xlinkHref={`${icons}#icon-bookmark`}></use>
          </svg>
        </div>
        {reg.name}
      </h3>

      <p className="company-details__item-text">
        <span>{reg.number}</span>
        <span>{reg.issued}</span>
        <span>Дата выдачи: {reg.dateOfIssue}</span>
        <span>Бланк: {reg.form}</span>
      </p>
    </div>
  );
}