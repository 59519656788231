import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import AdminSidebar from '../admin/AdminSidebar';
import '../../scss/admin/admin.scss';

const AdminLayout = () => {
 const isAuthenticated = localStorage.getItem('adminToken');
  if (!isAuthenticated) {
   return <Navigate to="/admin" />;
 }
  return (
   <div className="admin-layout">
     <AdminSidebar />
     <main className="admin-content">
       <Outlet />
     </main>
   </div>
 );
};
export default AdminLayout;