import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'react-responsive-modal';
import { doctorsApi } from '../../../api/doctors';
import noFoto from '../../../assets/img/avatar.svg';
import 'react-responsive-modal/styles.css';
import '../../../scss/admin/doctors.scss';

const AdminDoctors = () => {
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDoctors();
  }, []);

  const fetchDoctors = async () => {
    try {
      const data = await doctorsApi.fetchDoctors();
      setDoctorsList(data);
    } catch (error) {
      console.error('Ошибка при загрузке врачей:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (editedDoctor) => {
    try {
      await doctorsApi.saveDoctor(editedDoctor);
      await fetchDoctors();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Ошибка при сохранении врача:', error);
      alert('Произошла ошибка при сохранении');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Вы уверены, что хотите удалить этого врача?')) {
      try {
        await doctorsApi.deleteDoctor(id);
        await fetchDoctors();
      } catch (error) {
        console.error('Ошибка при удалении врача:', error);
        alert('Произошла ошибка при удалении');
      }
    }
  };

  const handleEdit = (doctor) => {
    setSelectedDoctor(doctor);
    setIsModalOpen(true);
  };

  if (loading) {
    return <div className="loading">Загрузка...</div>;
  }

  return (
    <div>
      <div className="admin-doctors">
        <div className="header">
          <h1>Управление врачами</h1>
          <button 
            className="add-button"
            onClick={() => handleEdit({
              id: uuidv4(),
              surname: '',
              name: '',
              specialty: '',
              specialtyFull: '',
              education: '',
              diplomas: [],
              url: '',
              img: noFoto
            })}
          >
            Добавить врача
          </button>
        </div>

        <table className="doctors-table">
          <thead>
            <tr>
              <th>Фото</th>
              <th>ФИО</th>
              <th>Должность</th>
              <th>Образование</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {doctorsList.map(doctor => (
              <tr key={doctor.id}>
                <td>
                  <img 
                    src={doctor.img}
                    alt={`${doctor.surname} ${doctor.name}`} 
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = noFoto;
                    }}
                  />
                </td>
                <td>{doctor.surname} {doctor.name}</td>
                <td>{doctor.specialtyFull}</td>
                <td>{doctor.education}</td>
                <td className="actions">
                  <button 
                    className="edit-button"
                    onClick={() => handleEdit(doctor)}
                  >
                    Редактировать
                  </button>
                  <button 
                    className="delete-button"
                    onClick={() => handleDelete(doctor.id)}
                  >
                    Удалить
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal 
          open={isModalOpen} 
          onClose={() => setIsModalOpen(false)}
          center
        >
          {selectedDoctor && (
            <DoctorEditForm
              doctor={selectedDoctor}
              onSave={handleSave}
              onCancel={() => setIsModalOpen(false)}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

const DoctorEditForm = ({ doctor, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    ...doctor,
    specialtyFull: doctor.specialtyFull || '',
    diplomas: doctor.diplomas || []
  });
  const [previewImage, setPreviewImage] = useState(doctor.img || noFoto);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setFormData(prev => ({
          ...prev,
          img: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDiplomaAdd = (e) => {
    const files = Array.from(e.target.files);
    
    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prev => ({
          ...prev,
          diplomas: [...prev.diplomas, {
            id: uuidv4(),
            img: reader.result
          }]
        }));
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDiplomaDelete = (diplomaId) => {
    setFormData(prev => ({
      ...prev,
      diplomas: prev.diplomas.filter(d => d.id !== diplomaId)
    }));
  };

  // Функция для транслитерации русского текста
  const transliterate = (text) => {
    const ru = {
      'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 
      'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
      'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
      'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
      'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'ts', 'ч': 'ch',
      'ш': 'sh', 'щ': 'sch', 'ъ': '', 'ы': 'y', 'ь': '',
      'э': 'e', 'ю': 'yu', 'я': 'ya'
    };

    return text.toLowerCase().split('').map(char => ru[char] || char).join('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Генерируем URL перед сохранением
    const surname = transliterate(formData.surname);
    const name = transliterate(formData.name.split(' ')[0]);
    const generatedUrl = `${surname}${name}`;

    // Добавляем URL к данным формы
    onSave({
      ...formData,
      url: generatedUrl
    });
  };

  return (
    <form onSubmit={handleSubmit} className="doctor-edit-form">
      <h2>{doctor.id ? 'Редактирование врача' : 'Добавление врача'}</h2>
      
      <div className="form-layout">
        <div className="photo-column">
          <div className="form-group">
            <label>Фото врача:</label>
            <div className="photo-preview">
              <img src={previewImage} alt="Предпросмотр" />
              <input type="file" accept="image/*" onChange={handleImageChange} />
            </div>
          </div>
        </div>

        <div className="fields-column">
          <div className="form-group">
            <label>Фамилия:</label>
            <input
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Имя и отчество:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Должность:</label>
            <input
              type="text"
              name="specialtyFull"
              value={formData.specialtyFull}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Образование:</label>
            <textarea
              name="education"
              value={formData.education}
              onChange={handleChange}
              rows="3"
            />
          </div>
        </div>
      </div>

      <div className="diplomas-section">
        <label>Дипломы и сертификаты:</label>
        <div className="diplomas-preview">
          {formData.diplomas.map(diploma => (
            <div key={diploma.id} className="diploma-item">
              <img src={diploma.img} alt="Диплом" />
              <button 
                type="button" 
                className="delete-button"
                onClick={() => handleDiplomaDelete(diploma.id)}
              >
                ×
              </button>
            </div>
          ))}
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleDiplomaAdd}
            className="diploma-input"
          />
        </div>
      </div>

      <div className="form-actions">
        <button type="submit" className="save-button">Сохранить</button>
        <button type="button" className="cancel-button" onClick={onCancel}>Отмена</button>
      </div>
    </form>
  );
};

export default AdminDoctors; 