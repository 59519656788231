// Базовая конфигурация для API
export const API_CONFIG = {
  baseURL: process.env.NODE_ENV === 'development' 
    ? 'http://localhost:3001'
    : process.env.REACT_APP_API_URL,
  
  // Общая функция для получения заголовков
  getHeaders: (additionalHeaders = {}) => {
    const headers = {
      'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
      ...additionalHeaders
    };

    // Добавляем 'Content-Type' только если он не установлен
    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json';
    }

    return headers;
  },

  // Общая функция для выполнения запросов
  async fetchApi(endpoint, options = {}) {
    const url = `${this.baseURL}${endpoint}`;
    
    // Используем заголовки только если не установлен флаг useDefaultHeaders
    const headers = options.useDefaultHeaders === false 
      ? { 'Authorization': `Bearer ${localStorage.getItem('adminToken')}` }
      : this.getHeaders(options.headers);

    if (process.env.NODE_ENV === 'development') {
      console.log('Fetching:', url);
      console.log('Options:', { ...options, headers });
    }

    try {
      const response = await fetch(url, {
        ...options,
        headers
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Произошла ошибка при выполнении запроса');
      }

      const data = await response.json();
      if (process.env.NODE_ENV === 'development') {
        console.log('Response:', data);
      }
      return data;
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  }
}; 