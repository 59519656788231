/**
 * Главный контентный блок сайта, включающий в себя различные секции.
 *
 * Компонент `MainContent` служит для отображения основного содержимого сайта,
 * включая секции "Преимущества", "Промо", "О нас", "Цены" и "Отзывы".
 * Каждая из этих секций представлена отдельным компонентом, который рендерится внутри `main` элемента.
 *
 * @component
 */

import Features from './Features';
import Promotion from './Promotion';
import About from './About';
import Price from './Price';
import Feedback from './Feedback';

export default function MainContent() {
  return (
    <main className="content">
      <Features />
      <Promotion />
      <About />
      <Price />
      <Feedback />
    </main>
  );
}