import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../../../api/auth';

export default function Login() {
  const [credentials, setCredentials] = useState({ login: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    
    try {
      const data = await authApi.login(credentials);
      localStorage.setItem('adminToken', data.token);
      navigate('/admin/doctors');
    } catch (error) {
      setError(error.message || 'Ошибка аутентификации');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Вход в админ-панель</h2>
        
        {error && (
          <div className="error-message" role="alert">
            {error}
          </div>
        )}
        
        <div className="form-group">
          <label htmlFor="login">Логин:</label>
          <input
            id="login"
            type="text"
            value={credentials.login}
            onChange={(e) => setCredentials({...credentials, login: e.target.value})}
            disabled={loading}
            required
            autoComplete="username"
            placeholder="Введите логин"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Пароль:</label>
          <input
            id="password"
            type="password"
            value={credentials.password}
            onChange={(e) => setCredentials({...credentials, password: e.target.value})}
            disabled={loading}
            required
            autoComplete="current-password"
            placeholder="Введите пароль"
          />
        </div>

        <button type="submit" disabled={loading} className="login-button">
          {loading ? 'Вход...' : 'Войти'}
        </button>
      </form>
    </div>
  );
} 