import { API_CONFIG } from './config';

export const contactsApi = {
  fetchContacts: () => 
    API_CONFIG.fetchApi('/api/contacts'),

  updateContacts: (data) => 
    API_CONFIG.fetchApi('/api/contacts', {
      method: 'PUT',
      body: JSON.stringify(data)
    })
}; 