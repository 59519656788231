import { v4 as uuidv4 } from 'uuid';

export const menu = [
  {
    url: '/doctors',
    name: 'Специалисты',
    id: uuidv4(),
  },
  {
    url: '/documents',
    name: 'Документы',
    id: uuidv4(),
  },
  {
    url: '/contacts',
    name: 'Контакты',
    id: uuidv4(),
  },
];