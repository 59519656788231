/**
 * Компонент `Hero` для создания главного блока на странице.
 *
 * @component
 * @param {Object} props - Свойства компонента.
 * @param {string} [props.height=''] - Высота компонента, влияет на класс CSS.
 * @param {string} [props.type=''] - Тип компонента, влияет на класс CSS.
 * @param {ReactNode} children - Дочерние элементы, отображаемые внутри компонента.
 */

export default function Hero({ height = '', type = '', children }) {
  return (
    <section className={`section-hero section-hero--height-${height} section-hero--${type}`}>
      {children}
    </section>
  );
} 