import { API_CONFIG } from './config';

export const vacanciesApi = {
  // Получить все вакансии
  fetchVacancies: () => 
    API_CONFIG.fetchApi('/api/vacancies/all'),

  // Создать новую вакансию
  createVacancy: (data) =>
    API_CONFIG.fetchApi('/api/vacancies', {
      method: 'POST',
      body: JSON.stringify(data)
    }),

  // Обновить существующую вакансию
  updateVacancy: (id, data) =>
    API_CONFIG.fetchApi(`/api/vacancies/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    }),

  // Удалить вакансию
  deleteVacancy: (id) =>
    API_CONFIG.fetchApi(`/api/vacancies/${id}`, {
      method: 'DELETE'
    })
}; 