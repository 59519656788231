/**
 * Компонент `СontactsList` для отображения информации о контактах.
 *
 * Этот компонент использует данные из `contactsList`, импортированные из '../../data/contacts',
 * для отображения списка контактов. Каждый контакт отображается с помощью компонента `СontactsItem`.
 * Компонент также включает карту, реализованную через компонент `MapScheme`.
 *
 * @component
 */

import React, { useState, useEffect, useContext } from 'react';
import { DoctorsContext } from '../../App';
import ContactsItem from '../elements/ContactsItem';
import MapScheme from '../elements/MapScheme';
import { contactsApi } from '../../api/contacts';

export default function ContactsList() {
  const { baseUrl } = useContext(DoctorsContext);
  const [contacts, setContacts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await contactsApi.fetchContacts();
        setContacts(data);
      } catch (error) {
        console.error('Ошибка загрузки контактов:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  if (loading || !contacts) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="container">
      <div className="section-hero__contacts contacts">
        <h1 className="section-hero__title" id="contacts">Контакты</h1>

        <ul className="contacts__list" aria-labelledby="contacts">
          <li className="contacts__item">
            <ContactsItem 
              item={{
                title: 'Телефон для записи:',
                text: contacts.phone,
                type: 'tel',
                svgId: 'tel'
              }} 
            />
          </li>
          <li className="contacts__item">
            <ContactsItem 
              item={{
                title: 'Режим работы:',
                text: contacts.working_hours,
                svgId: 'clock'
              }} 
            />
          </li>
          <li className="contacts__item">
            <ContactsItem 
              item={{
                title: 'Адрес:',
                text: contacts.address,
                svgId: 'pin'
              }} 
            />
          </li>
        </ul>

        <MapScheme type="contacts__map" />
      </div>
    </div>
  );
}