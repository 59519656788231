/**
 * Компонент страницы "Документы" для отображения информации о документах.
 *
 * @component
 */

import Hero from '../layout/Hero';
import Registration from '../blocks/Registration';
import Documents from '../blocks/Documents';

export default function DocumentsPage() {
  document.title = 'Dr. Teeth | Документы';
  
  return (
    <>
      <Hero height="auto" type="documents">
        <Registration />
      </Hero>
      <Documents />
    </>
  );
}