/**
 * Компонент главной страницы, отображающий слайдер и действия на главной странице.
 *
 * @component
 *
 * @remarks
 * Компонент использует `Swiper` и `SwiperSlide` для создания карусели слайдов, каждый из которых представлен компонентом `SlideHero`.
 * Также предоставляются кнопки для телефонного звонка и записи на прием, реализованные через `Link` и `ButtonAppointment` соответственно.
 *
 * @see {@link Swiper}
 * @see {@link SwiperSlide}
 * @see {@link SlideHero}
 * @see {@link ButtonAppointment}
 */

import { Link } from 'react-router-dom';
import { Swiper } from '../elements/Swiper';
import { SwiperSlide } from '../elements/SwiperSlide';
import { slidesHero } from '../../data/slides';
import img from '../../assets/img/hero-img.png';
import SlideHero from '../elements/SlideHero';
import ButtonAppointment from '../ui/ButtonAppointment';
import { phone } from '../../data/constants';

export default function Main() {
  return (
    <div className="container section-hero__wrapper">
      <div className="section-hero__slider">
        <Swiper
          id="hero-slider"
          loop="true"
          slidesPerView={1}
          spaceBetween={40}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          pagination={{
            el: '#hero-slider-pagination',
            type: 'bullets',
            clickable: true
          }}
        >
          {slidesHero.map(s => (
            <SwiperSlide key={s.id}>
              <SlideHero slide={s} />
            </SwiperSlide>
          ))}
        </Swiper>
        
        <div className="section-hero__actions">
          <Link to={`tel:${phone.link}`} className="section-hero__btn section-hero__btn--call btn btn--gold">Записаться</Link>
          <ButtonAppointment type="section-hero__btn section-hero__btn--appointment" />
          <div className="section-hero__pagination swiper-pagination" id="hero-slider-pagination"></div>
        </div>
      </div>
      
      <div className="section-hero__img-box">
        <img src={img} alt="" className="section-hero__img" />
      </div>
    </div>
  );
}