import { useState, useEffect } from 'react';
import { contactsApi } from '../../../api/contacts';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

export default function AdminContacts() {
  const [contacts, setContacts] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const data = await contactsApi.fetchContacts();
      setContacts(data);
    } catch (error) {
      console.error('Ошибка при загрузке контактов:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    
    const updatedContacts = {
      phone: formData.get('phone'),
      workingHours: formData.get('workingHours'),
      address: formData.get('address'),
      director: {
        name: formData.get('directorName'),
        hours: formData.get('directorHours'),
        email: formData.get('directorEmail')
      }
    };

    try {
      await contactsApi.updateContacts(updatedContacts);
      await fetchContacts();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Ошибка при сохранении контактов:', error);
      setError(error.message);
    }
  };

  if (loading) return <div>Загрузка...</div>;
  if (error) return <div>Ошибка: {error}</div>;
  if (!contacts) return <div>Нет данных</div>;

  return (
    <div className="admin-contacts">
      <h2>Управление контактами</h2>
      
      <div className="contacts-display">
        <div className="contact-info">
          <h3>Основная информация</h3>
          <p><strong>Адрес:</strong> {contacts.address}</p>
          <p><strong>Телефон:</strong> {contacts.phone}</p>
          <p><strong>Часы работы:</strong> {contacts.working_hours}</p>
        </div>

        <div className="director-info">
          <h3>Информация о директоре</h3>
          <p><strong>ФИО:</strong> {contacts.director_name}</p>
          <p><strong>Часы приёма:</strong> {contacts.director_hours}</p>
          <p><strong>Email:</strong> {contacts.director_email}</p>
        </div>

        <button 
          className="edit-button"
          onClick={() => setIsModalOpen(true)}
        >
          Редактировать
        </button>
      </div>

      <Modal 
        open={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        center
      >
        <form onSubmit={handleSave} className="contacts-form">
          <h3>Редактирование контактов</h3>
          
          <div className="form-group">
            <label>Адрес:</label>
            <input
              name="address"
              defaultValue={contacts.address}
              required
            />
          </div>

          <div className="form-group">
            <label>Телефон:</label>
            <input
              name="phone"
              defaultValue={contacts.phone}
              required
            />
          </div>

          <div className="form-group">
            <label>Часы работы:</label>
            <input
              name="workingHours"
              defaultValue={contacts.working_hours}
              required
            />
          </div>

          <div className="form-group">
            <label>ФИО директора:</label>
            <input
              name="directorName"
              defaultValue={contacts.director_name}
              required
            />
          </div>

          <div className="form-group">
            <label>Часы приёма директора:</label>
            <input
              name="directorHours"
              defaultValue={contacts.director_hours}
              required
            />
          </div>

          <div className="form-group">
            <label>Email директора:</label>
            <input
              name="directorEmail"
              type="email"
              defaultValue={contacts.director_email}
              required
            />
          </div>

          <div className="form-actions">
            <button type="submit" className="save-button">
              Сохранить
            </button>
            <button 
              type="button" 
              className="cancel-button"
              onClick={() => setIsModalOpen(false)}
            >
              Отмена
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
} 