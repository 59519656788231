/**
 * Компонент `Breadcrumbs` для отображения текущего пути в приложении.
 *
 * Использует хук `useMatches` из `react-router-dom` для получения информации о совпадениях маршрутов.
 * Фильтрует совпадения, содержащие функцию `crumb`, и отображает их в виде списка.
 *
 * @component
 */

import { useMatches } from 'react-router-dom';

export default function Breadcrumbs() {
  let matches = useMatches();
  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data));

  return (
    <ul className="breadcrumbs" aria-label="Навигационная цепочка">
      {crumbs.map((crumb, index) => (
        <li className="breadcrumbs__item" key={index}>
          {crumb}          
        </li>
      ))}
    </ul>
  );
}