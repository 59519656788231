/**
 * Компонент `SlideFeedback` для отображения отзыва о враче.
 *
 * Этот компонент используется для отображения отзыва, включая информацию о враче, рейтинг, текст отзыва и возможность перехода к полному тексту отзыва.
 *
 * @component
 * @param {Object} review - Объект с информацией об отзыве.
 * @param {string} review.phone - Телефонный номер, указанный в отзыве.
 * @param {string} review.avatar - Аватар автора отзыва.
 * @param {string} review.doctor - Имя врача, о котором оставлен отзыв.
 * @param {number} review.rating - Рейтинг отзыва.
 * @param {string} review.text - Текст отзыва.
 * @param {string} [review.link] - Ссылка на полный текст отзыва, если доступна.
 */

import { Link } from 'react-router-dom';
import prodoctorovLogo from '../../assets/img/logo_prodoctorov.png';
import Rating from './Rating';

export default function SlideFeedback({ review }) {
  return (
    <div className="feedback">
      <div className="feedback__head">
        <img className="feedback__img" src={prodoctorovLogo} alt="icon" />
        <div className="feedback__credentials">{review.phone}</div>
      </div>

      <div className="feedback__body">
        <div className="feedback__avatar-box">
          <img className="feedback__avatar-img" src={review.avatar} alt="avatar" />
        </div>

        <div className="feedback__content">
          <h6 className="feedback__title">Отзыв о {review.doctor}</h6>
          <Rating rating={review.rating} />
          <p className="feedback__text text">
            {review.text}
            {review.link && <Link to={review.link} className="read-more-link">Читать дальше</Link>}
          </p>
        </div>
      </div>
    </div>
  );
}