/**
 * Компонент админ-панели для управления документами.
 * 
 * @component
 */

import { useState, useEffect } from 'react';
import Modal from '../../elements/Modal';
import { v4 as uuidv4 } from 'uuid';
import { documentsApi } from '../../../api/documents';
import RegistrationForm from './forms/RegistrationForm';

export default function AdminDocuments() {
  const [documents, setDocuments] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editForm, setEditForm] = useState({
    title: '',
    type: '',
    content: '',
    licenses: []
  });
  const [editingRegistration, setEditingRegistration] = useState(null);
  const [registrationForm, setRegistrationForm] = useState({
    name: '',
    number: '',
    issued: '',
    dateOfIssue: '',
    form: ''
  });
  const [previewUrls, setPreviewUrls] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);

      const [docsData, regsData] = await Promise.all([
        documentsApi.fetchDocuments(),
        documentsApi.fetchRegistrations()
      ]);

      setDocuments(Array.isArray(docsData) ? docsData : []);
      setRegistrations(Array.isArray(regsData) ? regsData : []);
    } catch (error) {
      console.error('Ошибка загрузки данных:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    console.log('Начало обработки изменения файла');
  
    const files = Array.from(e.target.files);
    console.log(`Выбрано файлов: ${files.length}`);
  
    for (const file of files) {
      console.log(`Обработка файла: ${file.name}`);
  
      try {
        console.log('Отправка файла на сервер');
        const result = await documentsApi.uploadFile(file);
        console.log('Файл успешно загружен на сервер');
  
        setEditForm(prev => {
          console.log('Обновление состояния формы');
          return {
            ...prev,
            licenses: [...prev.licenses, {
              id: uuidv4(),
              document_id: prev.id,
              image_path: result.path.startsWith('/') ? result.path : `/${result.path}`
            }]
          };
        });
      } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
      }
    }
  
    console.log('Завершение обработки всех файлов');
  };

  const handleDeleteFile = async (licenseId) => {
    try {
      await documentsApi.deleteLicense(editForm.id, licenseId);
      
      // Обновляем состояние формы
      setEditForm(prev => ({
        ...prev,
        licenses: prev.licenses.filter(f => f.id !== licenseId)
      }));

      // Опционально: обновляем список документов
      await fetchData();
    } catch (error) {
      console.error('Ошибка при удалении файла:', error);
      alert('Произошла ошибка при удалении файла');
    }
  };

  // Функция для обработки нажатия на кнопку редактирования
  const handleEditDocument = (doc) => {
    
    setEditForm({
      id: doc.id,
      title: doc.title || '',
      type: doc.type || '',
      content: doc.content ? (Array.isArray(doc.content) ? doc.content.join('\n') : doc.content) : '',
      licenses: doc.licenses || []
    });
    setIsModalOpen(true);
  };

  const handleSaveDocument = async (e) => {
    e.preventDefault();
    
    try {
      // Форматируем данные в соответствии с тем, что видно в Network
      const formattedData = {
        id: editForm.id,
        title: editForm.title || '',
        content: editForm.content ? JSON.stringify([editForm.content]) : '[]',
        type: editForm.type || '',
        licenses: editForm.licenses ? editForm.licenses.map(license => ({
          id: license.id,
          document_id: editForm.id,
          image_path: license.image_path
        })) : []
      };

      await documentsApi.updateDocument(editForm.id, formattedData);
      await fetchData();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Ошибка при сохранении документа:', error);
      alert('Произошла ошибка при сохранении документа');
    }
  };

  const handleEditRegistration = (registration) => {
    setEditingRegistration(registration);
    setRegistrationForm({
      id: registration.id,
      name: registration.name,
      number: registration.number,
      issued: registration.issued,
      dateOfIssue: registration.dateOfIssue,
      form: registration.form
    });
    setIsModalOpen(true);
  };

  const handleSaveRegistration = async (e) => {
    e.preventDefault();
    try {
      await documentsApi.updateRegistration(registrationForm.id, registrationForm);
      await fetchData();
      setIsModalOpen(false);
      setEditingRegistration(null);
    } catch (error) {
      console.error('Ошибка при сохранении регистрационного документа:', error);
      alert('Произошла ошибка при сохранении регистрационного документа');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
  }, [documents]);

  useEffect(() => {
    return () => {
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, []);

  if (loading) {
    return <div className="admin-content">Загрузка...</div>;
  }

  if (error) {
    return <div className="admin-content">Ошибка: {error}</div>;
  }

  return (
    <div className="admin-documents">
      <h2>Управление документами</h2>
      
      <section className="admin-section">
        <h3>Регистрационные документы</h3>
        <div className="admin-table">
          <table>
            <thead>
              <tr>
                <th>Название</th>
                <th>Номер</th>
                <th>Кем выдан</th>
                <th>Дата выдачи</th>
                <th>Форма</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {registrations.length > 0 ? (
                registrations.map(reg => (
                  <tr key={reg.id}>
                    <td>{reg.name}</td>
                    <td>{reg.number}</td>
                    <td>{reg.issued}</td>
                    <td>{reg.dateOfIssue}</td>
                    <td>{reg.form}</td>
                    <td>
                      <button 
                        className="btn btn--edit"
                        onClick={() => handleEditRegistration(reg)}
                      >
                        Редактировать
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr><td colSpan="6">Нет доступных регистрационных документов</td></tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <div className="admin-table">
        <table className="admin-documents__table">
          <thead>
            <tr>
              <th>Название</th>
              <th>Тип</th>
              <th>Предпросмотр</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {documents.length > 0 ? (
              documents.map(doc => (
                <tr key={doc.id}>
                  <td>{doc.title}</td>
                  <td>{doc.type}</td>
                  <td>
                    {doc.licenses && doc.licenses.length > 0 && (
                      <div className="documents-preview table-preview">
                        {doc.licenses.map(license => (
                          <div key={license.id} className="document-item">
                            <img 
                              src={`${process.env.REACT_APP_API_URL}${license.image_path}`}
                              alt={`Документ ${doc.title}`} 
                              className="document-thumbnail"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </td>
                  <td>
                    <button 
                      className="btn btn--edit" 
                      onClick={() => handleEditDocument(doc)}
                    >
                      Редактировать
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Нет доступных документов</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Модальное окно редактирования */}
      {isModalOpen && (
        <Modal 
          onClose={() => {
            setIsModalOpen(false);
            setEditingRegistration(null);
          }} 
          onSave={editingRegistration ? handleSaveRegistration : handleSaveDocument}
        >
          {editingRegistration ? (
            <div className="form-container">
              <div className="form-content">
                <h3>Редактирование регистрационного документа</h3>
                <div className="form-group">
                  <label>Название:</label>
                  <input
                    type="text"
                    value={registrationForm.name}
                    onChange={(e) => setRegistrationForm({
                      ...registrationForm,
                      name: e.target.value
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>Номер:</label>
                  <input
                    type="text"
                    value={registrationForm.number}
                    onChange={(e) => setRegistrationForm({
                      ...registrationForm,
                      number: e.target.value
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>Кем выдан:</label>
                  <input
                    type="text"
                    value={registrationForm.issued}
                    onChange={(e) => setRegistrationForm({
                      ...registrationForm,
                      issued: e.target.value
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>Дата выдачи:</label>
                  <input
                    type="text"
                    value={registrationForm.dateOfIssue}
                    onChange={(e) => setRegistrationForm({
                      ...registrationForm,
                      dateOfIssue: e.target.value
                    })}
                  />
                </div>
                <div className="form-group">
                  <label>Форма:</label>
                  <input
                    type="text"
                    value={registrationForm.form}
                    onChange={(e) => setRegistrationForm({
                      ...registrationForm,
                      form: e.target.value
                    })}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="form-container">
              <div className="form-content">
                <h3>Редактирование документа</h3>
                <div className="form-group">
                  <label>Название:</label>
                  <input
                    type="text"
                    value={editForm.title}
                    onChange={(e) => setEditForm({...editForm, title: e.target.value})}
                    placeholder="Введите название документа"
                  />
                </div>
                
                <div className="form-group">
                  <label>Тип:</label>
                  <input
                    type="text"
                    value={editForm.type}
                    onChange={(e) => setEditForm({...editForm, type: e.target.value})}
                    placeholder="Введите тип документа"
                  />
                </div>
                
                <div className="form-group">
                  <label>Содержание (каждая строка с новой строки):</label>
                  <textarea
                    value={editForm.content}
                    onChange={(e) => setEditForm({...editForm, content: e.target.value})}
                    placeholder="Введите содержание документа"
                  />
                </div>
              </div>

              <div className="form-images">
                <label>Фотографии документов:</label>
                <div className="documents-preview">
                  {editForm.licenses?.map(license => (
                    <div key={license.id} className="document-item">
                      <img 
                        src={`${process.env.REACT_APP_API_URL}${license.image_path}`}
                        alt="Документ" 
                        className="document-thumbnail"
                      />
                      <button 
                        type="button" 
                        className="delete-button"
                        onClick={() => handleDeleteFile(license.id)}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                  {previewUrls.map((url, index) => (
                    <div key={`preview-${index}`} className="document-item">
                      <img 
                        src={url} 
                        alt="Предпросмотр" 
                        className="document-thumbnail preview"
                      />
                      <div className="upload-progress">Загрузка...</div>
                    </div>
                  ))}
                  <label className="upload-button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12 6v6m0 0v6m0-6h6m-6 0H6" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                    <span>Добавить фот</span>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="modal-actions">
            <button type="button" className="btn-secondary" onClick={() => setIsModalOpen(false)}>
              Отмена
            </button>
            <button type="submit" className="btn-primary">
              Сохранить
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
} 