import { useState, useEffect } from 'react';
import { documentsApi } from '../../api/documents';

export default function Documents() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const data = await documentsApi.fetchDocuments();
        // Фильтруем, исключая регистрационные документы
        const filteredDocs = data.filter(doc => doc.type !== 'registration');
        setDocuments(filteredDocs);
      } catch (error) {
        console.error('Ошибка при загрузке документов:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  if (loading) return <div>Загрузка...</div>;
  if (error) return <div>Ошибка: {error}</div>;

  return (
    <div className="documents">
      <div className="container">
        <h2>Документы</h2>
        <div className="documents__grid">
          {documents.map(doc => (
            <div key={doc.id} className="documents__item">
              <h3>{doc.title}</h3>
              {doc.licenses && doc.licenses.length > 0 && (
                <div className="documents__images">
                  {doc.licenses.map(license => (
                    <img
                      key={license.id}
                      src={`${process.env.REACT_APP_API_URL}${license.image_path}`}
                      alt={doc.title}
                      className="documents__image"
                    />
                  ))}
                </div>
              )}
              {doc.content && (
                <div className="documents__content">
                  {JSON.parse(doc.content).map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}