/**
 * Компонент `Service` для отображения информации о конкретной услуге.
 *
 * @component
 * @param {Object} service - Объект, содержащий информацию об услуге.
 * @param {string} service.name - Название услуги.
 * @param {number} service.price - Цена услуги.
 * @param {string} service.code - Код услуги.
 */

export default function Service({ service }) {
  return (
    <>
      <h6 className="price-list__title">{service.name}</h6>

      <div className="price-list__details">
        <span className="price-list__price">{service.price.toLocaleString('de')} ₽</span>
        <span className="price-list__code">Код: {service.code}</span>
      </div>
    </>
  );
}