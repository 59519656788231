/**
 * Компонент `Header` для отображения верхней части сайта.
 *
 * Этот компонент отвечает за отображение логотипа, навигационного меню, контактной информации и кнопки "гамбургер" для мобильных устройств.
 * Компонент также реагирует на прокрутку страницы, изменяя классы для стилизации в зависимости от положения прокрутки.
 *
 * @component
 */

import { useEffect, useState } from 'react';
import { MenuBurgerContext, MenuBurgerLoopContext } from '../../context/context';
import Logo from "../ui/Logo";
import Nav from '../elements/Nav';
import Contacts from '../elements/Contacts';
import ButtonBurger from '../ui/ButtonBurger';

export default function Header() {
  const [scrollTop, setScrollTop] = useState(0);
  const [classes, setClasses] = useState('header');
  const [btnClose, setBtnClose] = useState(null);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);

  const onScrollHeader = () => setScrollTop(window.scrollY);
  
  useEffect(() => {
    onScrollHeader();    
    window.addEventListener("scroll", onScrollHeader);
    
    return () => {
      window.removeEventListener("scroll", onScrollHeader);
    };
  }, []);
  
  useEffect(() => {
    scrollTop > 60 ? setClasses('header header--sticky') : setClasses('header');
  }, [scrollTop]);

  return (
    <MenuBurgerContext.Provider value={{ isBurgerOpen, setIsBurgerOpen }}>
      <MenuBurgerLoopContext.Provider value={{ btnClose, setBtnClose }}>
        <header className={classes}>
          <div className="container">
            <div className="header__wrapper">
              <Logo location="header"/>
              <ButtonBurger />          
              <Nav />
              <Contacts location="header" />
            </div>
          </div>
        </header>
      </MenuBurgerLoopContext.Provider>
    </MenuBurgerContext.Provider>
  );
}