/**
 * Компонент `СontactsRule` для отображения дополнительной информации в разделе Контакты.
 *
 * @component
 * @param {Object} rule - Объект с информацией.
 * @param {string} rule.title - Заголовок.
 * @param {string} rule.director - ФИО директора клиники (опционально).
 * @param {string} rule.desc - Описание.
 * @param {boolean} rule.link - Флаг, указывающий на наличие ссылки на электронную почту.
 */

import { Link } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { DoctorsContext } from '../../App';

export default function ContactsRule({ rule }) {
  const { baseUrl } = useContext(DoctorsContext);
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/contacts`);
        const data = await response.json();
        setContacts(data);
      } catch (error) {
        console.error('Ошибка загрузки контактов:', error);
      }
    };

    fetchContacts();
  }, [baseUrl]);

  if (!contacts) {
    return null;
  }

  return (
    <div className="contact-page__section">
      <div className="container">
        <div className="contact-page__section-title">{rule.title}</div>
        <div className="contact-page__section-desc">
          {rule.director && rule.director}
          <div>{rule.desc}</div>
          {rule.link && (
            <div>
              <Link to={`mailto:${contacts.director_email}`}>{contacts.director_email}</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}