/**
 * Компонент `Footer` для отображения нижней части сайта.
 *
 * Включает в себя логотип, контактную информацию, карту и кнопку для быстрого перехода вверх.
 *
 * @component
 */

import Logo from '../ui/Logo';
import Contacts from '../elements/Contacts';
import MapScheme from '../elements/MapScheme';

export default function Footer() {
  const onClickUpButton = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="container">
          <div className="footer__wrapper">
            <Logo location="footer" />

            <div className="footer__content">
              <div className="footer__text">
                <h5 className="footer__title">Частная стоматологическая клиника «Dr.Teeth»</h5>
                <p className="text">
                  Лицензия на осуществление медицинской деятельности №ЛО-27-01-002915 от 20.03.2020г выдана министерством здравоохранения Хабаровского края (г. Хабаровск, ул. Муравьева-Амурского, 32; тел.: +7 (4212) 40-23-22, факс: +7 (4212) 40-24-51; электронная почта: zdrav@adm.khv.ru)
                </p>
              </div>

              <Contacts location="footer" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer__divider"></div>
      <button className="btn-scroll-to-top" onClick={onClickUpButton}>Наверх</button>
      <MapScheme type="footer__map" />
    </footer>
  );
}