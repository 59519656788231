import { API_CONFIG } from './config';

export const documentsApi = {
  fetchDocuments: () => 
    API_CONFIG.fetchApi('/api/documents'),

  getDocument: (id) =>
    API_CONFIG.fetchApi(`/api/documents/${id}`),

  updateDocument: (id, data) =>
    API_CONFIG.fetchApi(`/api/documents/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    }),

  fetchRegistrations: () =>
    API_CONFIG.fetchApi('/api/documents/registrations'),

  updateRegistration: (id, data) =>
    API_CONFIG.fetchApi(`/api/documents/registrations/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data)
    }),

  deleteLicense: (documentId, licenseId) => 
    API_CONFIG.fetchApi(`/api/documents/${documentId}/licenses/${licenseId}`, {
      method: 'DELETE'
    }),

  uploadLicense: (documentId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    return API_CONFIG.fetchApi(`/api/documents/${documentId}/licenses`, {
      method: 'POST',
      body: formData,
      useDefaultHeaders: false
    });
  },

  uploadFile: (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return API_CONFIG.fetchApi('/api/documents/upload', {
      method: 'POST',
      body: formData,
      useDefaultHeaders: false
    });
  }
}; 