/**
 * Компонент для отображения информации про отдельного врача.
 *
 * Этот компонент использует хук `useParams` для получения параметра `url` из URL,
 * который затем используется для поиска соответствующего врача в массиве `doctors`.
 * После нахождения врача, информация о нем отображается с помощью компонента `Doctor`.
 *
 * @component
 * @param {string} url - Параметр URL, используемый для поиска врача.
 *
 * @see {@link https://dev.to/javila35/react-router-hook-useparam-now-w-typescript-m93 | Использование useParams с TypeScript}
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Hero from '../layout/Hero';
import Doctor from '../blocks/Doctor';
import { doctorsApi } from '../../api/doctors';

export default function DoctorPage() {
  const { url } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDoctor = async () => {
      try {
        const doctors = await doctorsApi.fetchDoctors();
        const foundDoctor = doctors.find(d => d.url === url);
        setDoctor(foundDoctor);
      } catch (error) {
        console.error('Ошибка при загрузке врача:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctor();
  }, [url]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <Hero height="auto" type="doctors">
      {doctor ? <Doctor doctor={doctor} /> : <div>Врач не найден</div>}
    </Hero>
  );
}