import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Routes, Route } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import ScrollToTop from './components/elements/ScrollToTop/ScrollToTop';
import MainPage from './components/pages/MainPage';
import DoctorsPage from './components/pages/DoctorsPage';
import DoctorPage from './components/pages/DoctorPage';
import DocumentsPage from './components/pages/DocumentsPage';
import AdminLayout from './components/admin/AdminLayout';
import AdminLogin from './components/pages/admin/AdminLogin';
import AdminDoctors from './components/pages/admin/AdminDoctors';
import AdminDocuments from './components/pages/admin/AdminDocuments';
import ContactsPage from './components/pages/ContactsPage';
import AdminContacts from './components/pages/admin/AdminContacts';
import AdminVacancies from './components/pages/admin/AdminVacancies';
import AdminPrices from './components/pages/admin/AdminPrices';
import Price from './components/blocks/Price';
import { 
  MenuBurgerContext, 
  MenuBurgerLoopContext,
  PopupContext 
} from './context/context';

import './scss/main.scss';

// Создаем контекст для данных о врачах и документах
export const DoctorsContext = React.createContext(null);
export const DocumentsContext = React.createContext(null);

// Создаем компонент Layout
function Layout() {
  return (
    <div className="wrapper">
      <ScrollToTop />
      <Header />
      <main className="main">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/doctors" element={<DoctorsPage />} />
          <Route path="/doctors/:url" element={<DoctorPage />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/price" element={<Price />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin/*" element={<AdminLayout />}>
            <Route path="doctors" element={<AdminDoctors />} />
            <Route path="documents" element={<AdminDocuments />} />
            <Route path="contacts" element={<AdminContacts />} />
            <Route path="vacancies" element={<AdminVacancies />} />
            <Route path="prices" element={<AdminPrices />} />
          </Route>
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

// Создаем конфигурацию роутера
const router = createBrowserRouter([
  {
    path: "*",
    element: <Layout />
  }
]);

function App() {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [btnClose, setBtnClose] = useState(null);
  const [popup, setPopup] = useState({
    type: null,
    isOpen: false
  });

  useEffect(() => {
    console.log('Environment:', {
      API_URL: process.env.REACT_APP_API_URL,
      NODE_ENV: process.env.NODE_ENV
    });
  }, []);

  return (
    <DoctorsContext.Provider value={{ baseUrl: process.env.REACT_APP_API_URL }}>
      <DocumentsContext.Provider value={{ baseUrl: process.env.REACT_APP_API_URL }}>
        <MenuBurgerContext.Provider value={{ isBurgerOpen, setIsBurgerOpen }}>
          <MenuBurgerLoopContext.Provider value={{ btnClose, setBtnClose }}>
            <PopupContext.Provider value={{ popup, setPopup }}>
              <RouterProvider router={router} />
            </PopupContext.Provider>
          </MenuBurgerLoopContext.Provider>
        </MenuBurgerContext.Provider>
      </DocumentsContext.Provider>
    </DoctorsContext.Provider>
  );
}

export default App; 