/**
 * Компонент `Rating` для отображения иконок звезд в зависимости от заданного рейтинга.
 *
 * Компонент принимает рейтинг в виде числа и отображает соответствующее количество звезд.
 * Если рейтинг является дробным числом, отображается половина звезды.
 *
 * @component
 * @param {number} rating - Рейтинг, который нужно отобразить.
 */

import { v4 as uuidv4 } from 'uuid';
import { ratingMax } from '../../data/constants';
import Star from '../ui/Star';

export default function Rating({ rating }) {
  const isInteger = Number.isInteger(rating);
  const integer = Math.floor(rating);
  const ratingArr = Array(ratingMax).fill("empty");

  rating > 0 && (
    !integer ? ratingArr.fill("half", 0, 1) : ratingArr.fill("full", 0, integer)
  );

  integer && !isInteger && ratingArr.fill('half', integer, integer + 1);

  return (
    <div className="feedback__rating">
      {ratingArr.map(i => <Star fullness={i} key={uuidv4()} />)}
    </div>
  );
}