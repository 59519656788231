/**
 * Компонент `ContactsItem` для отображения информации о контакте.
 *
 * Этот компонент использует SVG-спрайт для отображения иконок контактов.
 * В зависимости от типа контакта (`item.type`), отображает информацию либо как телефонный номер, либо как дополнительные детали.
 *
 * @component
 * @param {Object} item - Объект с информацией о контакте.
 * @param {string} item.svgId - Идентификатор иконки для контакта.
 * @param {string} item.title - Заголовок контакта.
 * @param {string} item.text - Текстовое описание контакта.
 * @param {string} item.type - Тип контакта.
 */

import icons from "../../assets/img/icons.svg";

export default function ContactsItem({ item }) {
  return (
    <>
      <svg className="contacts__item-icon" width="24" height="24">
        <use xlinkHref={`${icons}#icon-${item.svgId}`}></use>
      </svg>
      
      <div className="contacts__item-title">{item.title}</div>
      {item.type === 'tel' ? (
        <a href={`tel:${item.text.replace(/[^\d+]/g, '')}`} className="contacts__item-tel">
          {item.text}
        </a>
      ) : (
        <p className="contacts__item-details">{item.text}</p>
      )}
    </>
  );
}