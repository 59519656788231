/**
 * Компонент `Features` отображает список преимуществ.
 *
 * Этот компонент перебирает массив `features`, импортированный из '../../data/features',
 * и отображает каждую функцию с помощью компонента `Feature`.
 *
 * @component
 */

import { features } from '../../data/features';
import Feature from '../elements/Feature';

export default function Features() {
  return (
    <div className="features">
      <ul className="features__list" aria-label="Преимущества">
        {features.map(f => (
          <li className="features__item feature" key={f.id}>
            <Feature feature={f} />            
          </li>
        ))}
      </ul>
    </div>
  );
}