/**
 * Компонент страницы "Специалисты" для отображения списка врачей и вакансий.
 *
 * @component
 *
 * @remarks
 * Компонент `DoctorsPage` использует Swiper для создания карусели врачей на больших экранах и переключается на обычный список на маленьких экранах.
 * Использует хук `useState` для управления состоянием `isSwiper`, которое определяет, отображать ли карусель или обычный список.
 * Хук `useEffect` используется для обработки события изменения размера окна и обновления состояния `isSwiper` в соответствии с текущим размером экрана.
 *
 * @see {@link Swiper}
 * @see {@link SwiperSlide}
 * @see {@link DoctorCard}
 */

import { useEffect, useState, useContext } from 'react';
import { Swiper } from '../elements/Swiper';
import { SwiperSlide } from '../elements/SwiperSlide';
import Hero from '../layout/Hero';
import DoctorCard from '../elements/DoctorCard';
import Vacancies from '../blocks/Vacancies';
import { doctorsApi } from '../../api/doctors';

export default function DoctorsPage() {
  const [doctors, setDoctors] = useState([]);
  const [isSwiper, setIsSwiper] = useState(true);
  const [loading, setLoading] = useState(true);
  const mediaQuery = window.matchMedia('(min-width: 650px)');

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const data = await doctorsApi.fetchDoctors();
        setDoctors(data);
      } catch (error) {
        console.error('Ошибка при загрузке врачей:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, []);

  // Обработка адаптивности
  const handleView = () => setIsSwiper(mediaQuery.matches);

  useEffect(() => {
    handleView();
    window.addEventListener('resize', handleView);
    return () => window.removeEventListener('resize', handleView);
  }, []);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <>
      <Hero height="auto" type="doctors">
        <div className="container">
          <div className="section-hero__head">
            <h1 className="section-hero__title">Специалисты</h1>
            <div className="swiper-pagination" id="doctors-slider-pagination"></div>
          </div>

          {isSwiper ? (
            <Swiper
              id="doctors-slider"
              className="doctors"
              loop="true"
              spaceBetween={48}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              breakpoints={{
                650: {
                  slidesPerView: 2
                },
                960: {
                  slidesPerView: 3
                },
                1366: {
                  slidesPerView: 4
                }
              }}
              pagination={{
                el: '#doctors-slider-pagination',
                type: 'bullets',
                clickable: true,
                dynamicBullets: true,
                dynamicMainBullets: 5,
              }}
            >
              {doctors.map(d => (
                <SwiperSlide className="doctors__item" key={d.id}>
                  <DoctorCard doctor={d} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="doctors" id="doctors-slider">
              {doctors.map(d => (
                <div className="doctors__item" key={d.id}>
                  <DoctorCard doctor={d} />
                </div>
              ))}
            </div>
          )}
        </div>
      </Hero>
      <Vacancies />
    </>
  );
}