import { API_CONFIG } from './config';

export const authApi = {
  login: (credentials) => 
    API_CONFIG.fetchApi('/api/auth/login', {
      method: 'POST',
      body: JSON.stringify(credentials)
    }),
    
  logout: () => 
    API_CONFIG.fetchApi('/api/auth/logout', {
      method: 'POST'
    }),

  checkAuth: () => 
    API_CONFIG.fetchApi('/api/auth/check')
}; 