import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MainPage from './components/pages/MainPage';
import DoctorsPage from './components/pages/DoctorsPage';
import DoctorPage from './components/pages/DoctorPage';
import DocumentsPage from './components/pages/DocumentsPage';
import ContactsPage from './components/pages/ContactsPage';
import ErrorPage from './components/pages/ErrorPage';
import AdminDoctors from './components/pages/admin/AdminDoctors';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();